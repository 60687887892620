import React from "react";
import Conclusion from "../../components/project/Conclusion";
import CommonProjectComponent from "../../components/project/CommonProjectComponent";
import ProblemSolution from "../../components/project/ProblemSolution";
import ProjectDetailBanner from "../../components/project/ProjectDetailBanner";
import PreviousNext from "../../components/PreviousNext";
import { Seo } from "../../components/seo";
import Layout from "../../components/layout";

const LikeHire = () => {
  const headingbanner = {
    title: `LikeHire`,
    content: `A Healthcare Job Portal Platform`,
  };

  const data = {
    images: ["like-hire.webp", "like-hire-2.webp"],
    firstAlt: "Landing Page Of Healthcare Job Platform",
    firstTitle: "Landing Page",
    secondAlt: "UI Design Of Total No of Job Applicants",
    secondTitle: "Applicant's Screen",
  };

  const problemSolutionContent = {
    sidebar: [
      {
        title: "Industry",
        text: ["HealthTech"],
      },
      {
        title: "What we did",
        text: ["Branding", "UI UX Designing", "VueJs Development"],
      },
      {
        title: "Platform",
        text: ["Web Portal", "Mobile Responsive"],
      },
    ],
    mainContent: [
      {
        title: "Problem",
        content: [
          `There is a growing need for a comprehensive healthcare job portal platform that can efficiently connect healthcare job seekers with potential employers. `,
          `Despite the existence of numerous job portals, there is a lack of a dedicated platform that caters specifically to the healthcare industry.`,
          `This leads to a fragmented and disorganised job market, making it difficult for both job seekers and employers to find the right fit.`,
        ],
      },
      {
        title: "Solution",
        content: [
          `The platform will feature customisable filters that job seekers can use to refine their job search based on qualifications, experience, location, and other preferences. `,
          `Employers will also be able to filter job seekers based on their qualifications and skills, making it easier to find the right candidate.`,
          `Our platform will use advanced algorithms to match job seekers with relevant job postings, based on their qualifications and skills.`,
          `Employers will also be able to use the platform to search for qualified candidates and efficiently screen applicants.`,
        ],
      },
    ],
    image: ["like-hire-problem.webp"],
    firstAlt: "Filter and Search jobs based on various categories",
    firstTitle: "Jobs by Speciality",
  };

  const projectData = {
    title: `Hire Top Healthcare Professionals`,
    para: [
      `The platform connects employers with top healthcare professionals, making it easy to find and hire the best talent in the industry. With a user-friendly interface and advanced search options, you can quickly find candidates with the skills and experience you need.`,
    ],
    content: {
      imageAlt: "UI UX screen to login into job portal platform",
      imageTitle: "Login Screen",
      image: ["like-hire-projectone.webp"],
      text: `We created a user-friendly login screens for the recruiters and job seekers to facilitate smooth platform experience.`,
    },
    content2: {
      imageAlt: "UI Dashboard to check the application insights",
      imageTitle: "Dasboard",
      image: ["like-hire-projectone-2.webp"],
      text: `We created this screen for the recruiters to get an overview of all the applications received and its status. With the use of bar graph, we want to show the number of applications on each particular day for the recruiters to keep track on the performance.`,
    },
  };

  const projectData2 = {
    title: `Get Hired`,
    para: [
      `This specific screen is for job seekers looking to get hired in the healthcare industry. `,
      `We added filters in the screen for the job seekers to get a user-friendly and accessible platform experience.`,
    ],
    content: {
      imageAlt: "Search for jobs by categories and location",
      imageTitle: "Search Jobs",
      image: ["like-hire-projecttwo.webp"],
    },
  };

  const projectData3 = {
    title: `Get The Job Easily`,
    para: [
      `We created a web and mobile version of the platform to offer easy accessible solutions. `,
      `In order to facilitate easy job search and get personalised platform experience, we provided location option along with the search bar option to the users.`,
    ],
    content: {
      image: [
        "like-hire-projectthree.webp",
        "like-hire-projectthree-2.webp",
        "like-hire-projectthree-3.webp",
        "like-hire-projectthree-4.webp",
        "like-hire-projectthree-5.webp",
        "like-hire-projectthree-6.webp",
        "like-hire-projectthree-7.webp",
      ],
      firstAlt: "Landing Page of a Healthcare Job Portal Platform",
      firstTitle: "Landing Page",
      secondAlt: "UI Screen to add personal details",
      secondTitle: "Add Personal Details",
      thirdAlt: "UI Screen to get profile overview of the candidate",
      thirdTitle: "My Profile",
      fourthAlt: "UI Screen to check the shortlisted jobs ",
      fourthTitle: "Shortlisted Jobs",
      fifthAlt: "Check the applications received for a particular job",
      fifthTitle: "All Jobs",
      sixthAlt: "Applicants status with their personal detials",
      sixthTitle: "Applicants Status",
      seventhAlt: "View applicants details alongwith cover letter",
      seventhTitle: "Applicants Details",
    },
  };

  const conclusionData = [
    {
      para: [
        `The healthcare job portals have become increasingly popular among healthcare organisations, as they provide a cost-effective solution for hiring qualified staff. `,
        `Healthcare employers can easily search for candidates that meet their specific requirements and can quickly fill their vacancies, saving time and resources.`,
      ],
    },
  ];

  return (
    <Layout>
      <div className="service-page">
        <ProjectDetailBanner data={data} headingbanner={headingbanner} />
        <ProblemSolution data={problemSolutionContent} />
        <CommonProjectComponent data={projectData} mode="cream" />
        <CommonProjectComponent data={projectData2} />
        <CommonProjectComponent data={projectData3} mode="dark" />
        <Conclusion conclusionData={conclusionData} />
        <PreviousNext currentProjectId={5} />
      </div>
    </Layout>
  );
};

export default LikeHire;

export const Head = () => (
  <Seo
    title="LikeHire - Octet Design Studio"
    description="We transformed and simplified US based healthcare portal through effective UI UX design and frontend development."
  />
);
